import React, { useEffect, useRef, useState, useCallback } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Environment, OrbitControls, Stats } from "@react-three/drei";
import * as CANNON from 'cannon-es';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const world = new CANNON.World({
  gravity: new CANNON.Vec3(0, -9.81, 0),
});

function Character({ jump, onCollision, onOutOfBounds }) {
  const ref = useRef();
  const sphereBody = useRef();
  const { scene } = useLoader(GLTFLoader, '/models/birdie.glb');

  useEffect(() => {
    const shape = new CANNON.Sphere(1);
    const body = new CANNON.Body({
      mass: 1,
      position: new CANNON.Vec3(0, 2, 0),
      shape: shape,
    });
    sphereBody.current = body;
    world.addBody(body);

    sphereBody.current.addEventListener('collide', (e) => {
      const { body } = e;
      if (body?.userData?.type === 'wall') {
        onCollision();
      }
    });

    return () => {
      world.removeBody(body);
    };
  }, [onCollision]);

  useFrame(() => {
    if (jump.current) {
      sphereBody.current.velocity.set(0, 5, 0);
      jump.current = false;
    }
    if (ref.current && sphereBody.current) {
      ref.current.position.copy(sphereBody.current.position);

      // Check if the bird goes out of bounds
      if (sphereBody.current.position.y > 10 || sphereBody.current.position.y < -10) {
        onOutOfBounds(); // Game Over if bird is out of bounds
      }
    }
  });

  return (
    <primitive
      ref={ref}
      object={scene}
      castShadow
      dispose={null}
      scale={1}
    />
  );
}

function Wall({ id, position, onRemove, onPass }) {
  const ref = useRef();
  const wallBody = useRef();
  const hasPassed = useRef(false);

  useEffect(() => {
    const shape = new CANNON.Box(new CANNON.Vec3(1, 4, 0.5));
    const body = new CANNON.Body({
      type: CANNON.Body.KINEMATIC,
      shape: shape,
      position: new CANNON.Vec3(position.x, position.y, position.z),
    });

    body.userData = { type: 'wall' };

    wallBody.current = body;
    world.addBody(body);

    return () => {
      world.removeBody(body);
    };
  }, [position]);

  useFrame((state, delta) => {
    if (wallBody.current) {
      wallBody.current.position.z -= delta * 5;
      ref.current.position.copy(wallBody.current.position);

      if (!hasPassed.current && wallBody.current.position.z < 0) {
        hasPassed.current = true;
        onPass();
      }

      if (wallBody.current.position.z < -10) {
        world.removeBody(wallBody.current);
        onRemove(id);
      }
    }
  });

  return (
    <mesh ref={ref} castShadow receiveShadow>
      <boxGeometry args={[2, 8, 1]} />
      <meshStandardMaterial color="lightblue" />
    </mesh>
  );
}

function Physics({ onCollision, onOutOfBounds }) {
  const jump = useRef(false);
  const lastTime = useRef(0);

  const handleJump = () => {
    jump.current = true;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        handleJump();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('click', handleJump);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleJump);
    };
  }, []);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    const delta = time - lastTime.current;
    world.step(1 / 60, delta, 3);
    lastTime.current = time;
  });

  return <Character jump={jump} onCollision={onCollision} onOutOfBounds={onOutOfBounds} />;
}

function Fb3d() {
  const [walls, setWalls] = useState([]);
  const wallId = useRef(0);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const handleCollision = useCallback(() => {
    setGameOver(true);
  }, []);

  const handleOutOfBounds = useCallback(() => {
    setGameOver(true);
  }, []);

  const startGame = () => {
    setGameStarted(true);
    setGameOver(false);
    setScore(0);
  };

  const resetGame = () => {
    setScore(0);
    setWalls([]);
    setGameOver(false);
    setGameStarted(false);
  };

  const removeWall = useCallback((id) => {
    setWalls((prevWalls) => prevWalls.filter(wall => wall.id !== id));
  }, []);

  const incrementScore = useCallback(() => {
    if (!gameOver) {
      setScore((prevScore) => prevScore + 1);
    }
  }, [gameOver]);

  useEffect(() => {
    if (gameOver || !gameStarted) return;

    const intervalId = setInterval(() => {
      const gapSize = 4;
      const wallHeight = 16;

      const minYPosition = -5;
      const maxYPosition = 4;
      const gapYPosition = Math.random() * (maxYPosition - minYPosition) + minYPosition;
        
      const initialZPosition = 24;
        
      const upperWallPosition = { x: 0, y: gapYPosition + (gapSize / 2 + wallHeight / 2), z: initialZPosition };
      const lowerWallPosition = { x: 0, y: upperWallPosition.y - (wallHeight/2) - gapSize, z: initialZPosition };
      
      setWalls((prevWalls) => [
        ...prevWalls,
        { id: wallId.current++, position: lowerWallPosition, isUpper: false },
        { id: wallId.current++, position: upperWallPosition, isUpper: true },
      ]);

    }, 3000);

    return () => clearInterval(intervalId);
  }, [gameOver, gameStarted]);

  return (
    <div className="fb3d d-flex" id="fb3d">
      <div className="gameMenu">
        <div className="score">Score: {score / 2}</div>
        {gameOver && (
          <div className="game-over">
            Game Over<br />
            <button onClick={resetGame}>Restart</button>
          </div>
        )}
        {!gameStarted && !gameOver && (
          <div className="game-start">
            <button onClick={startGame}>Start</button>
          </div>
        )}
      </div>
      <Canvas camera={{ fov: 75, position: [-12, 2, -10] }} shadows>
        <ambientLight intensity={0.9} color={0x404040} />
        <directionalLight
          intensity={1.8}
          position={[10, 10, 10]}
          castShadow
          color={0xFFC0CB}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        {gameStarted && !gameOver && <Physics onCollision={handleCollision} onOutOfBounds={handleOutOfBounds} />}
        {walls.map((wall) => (
          <Wall key={wall.id} id={wall.id} position={wall.position} onRemove={removeWall} onPass={incrementScore} />
        ))}
        {/* <OrbitControls /> */}
        <Environment preset="sunset" background blur={1} />
        <Stats />
      </Canvas>
    </div>
  );
}

export default Fb3d;
