import React from "react";
import Wam2 from "./Wam2.js";
import Fb3d from "./Fb3d.js";


function Games() {
     return <div className="Games">
   
          <Wam2 />
          <Fb3d />
     </div>
}
export default Games;
